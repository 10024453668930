import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import VueGtm from "vue-gtm";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import PrimeVue from "primevue/config";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core CSS
import "primeicons/primeicons.css"; //icons
import loadGA4 from "./utils/loadGA4";

const app = createApp(App);

// Initialize GTM only in production
if (process.env.NODE_ENV == "production") {
  if (process.env.VUE_APP_GTM_ID) {
    app.use(VueGtm, {
      id: process.env.VUE_APP_GTM_ID,
      vueRouter: router,
      enabled: true,
      debug: false,
    });
  }

  if (process.env.VUE_APP_GA4_ID) {
    loadGA4(process.env.VUE_APP_GA4_ID);
  }
}

app.use(PrimeVue);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
