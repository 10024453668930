const loadGA4 = (id: string) => {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]) {
    (window.dataLayer as any).push(args);
  }
  gtag("js", new Date());
  gtag("config", id);
};

export default loadGA4;
